/*!
 * bsStepper v1.7.0 (https://github.com/Johann-S/bs-stepper)
 * Copyright 2018 - 2019 Johann-S <johann.servoire@gmail.com>
 * Licensed under MIT (https://github.com/Johann-S/bs-stepper/blob/master/LICENSE)
 */
.bs-stepper .step-trigger {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
  color: #6c757d;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: none;
  border-radius: .25rem;
  transition: background-color .15s ease-out,color .15s ease-out; }

.bs-stepper .step-trigger:not(:disabled):not(.disabled) {
  cursor: pointer; }

.bs-stepper .step-trigger.disabled, .bs-stepper .step-trigger:disabled {
  pointer-events: none;
  opacity: .65; }

.bs-stepper .step-trigger:focus {
  color: #007bff;
  outline: 0; }

.bs-stepper .step-trigger:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.06); }

@media (max-width: 520px) {
  .bs-stepper .step-trigger {
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px; } }

.bs-stepper-label {
  display: inline-block;
  margin: .25rem; }

.bs-stepper-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }

@media (max-width: 520px) {
  .bs-stepper-header {
    margin: 0 -10px;
    text-align: center; } }

.bs-stepper .line, .bs-stepper-line {
  -ms-flex: 1 0 32px;
  flex: 1 0 32px;
  min-width: 1px;
  min-height: 1px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.12); }

@media (max-width: 400px) {
  .bs-stepper .line, .bs-stepper-line {
    -ms-flex-preferred-size: 20px;
    flex-basis: 20px; } }

.bs-stepper-circle {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: .5em 0;
  margin: .25rem;
  line-height: 1em;
  color: #fff;
  background-color: #6c757d;
  border-radius: 1em; }

.active .bs-stepper-circle {
  background-color: #007bff; }

.bs-stepper-content {
  padding: 0 20px 20px; }

@media (max-width: 520px) {
  .bs-stepper-content {
    padding: 0; } }

.bs-stepper.vertical {
  display: -ms-flexbox;
  display: flex; }

.bs-stepper.vertical .bs-stepper-header {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin: 0; }

.bs-stepper.vertical .bs-stepper-pane, .bs-stepper.vertical .content {
  display: block; }

.bs-stepper.vertical .bs-stepper-pane:not(.fade), .bs-stepper.vertical .content:not(.fade) {
  display: block;
  visibility: hidden; }

.bs-stepper .content:not(.fade), .bs-stepper-pane:not(.fade) {
  display: none; }

.bs-stepper .content.fade, .bs-stepper-pane.fade {
  visibility: hidden;
  transition-duration: .3s;
  transition-property: opacity; }

.bs-stepper .content.fade.active, .bs-stepper-pane.fade.active {
  visibility: visible;
  opacity: 1; }

.bs-stepper .content.active:not(.fade), .bs-stepper-pane.active:not(.fade) {
  display: block;
  visibility: visible; }

.bs-stepper .content.dstepper-block, .bs-stepper-pane.dstepper-block {
  display: block; }

.bs-stepper:not(.vertical) .bs-stepper-pane.dstepper-none, .bs-stepper:not(.vertical) .content.dstepper-none {
  display: none; }

.vertical .bs-stepper-pane.fade.dstepper-none, .vertical .content.fade.dstepper-none {
  visibility: hidden; }

/*# sourceMappingURL=bs-stepper.min.css.map */
